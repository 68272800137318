<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2022-05-19 10:46:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\components\tabs\index.vue
-->
<template>
  <div class="tabs" ref="tabs">
    <el-tabs v-model="activeTab" closable @tab-remove="tabRemove" type="card" @tab-click="tabClick">
      <el-tab-pane
        :key="item.path"
        v-for="item in currentTabs"
        :label="item.title"
        :name="item.path"
      >
        <!-- {{item.title}} -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as utils from "@/common/js/utils.js";
import VueRouter from "vue-router";
import store from "../../store";
export default {
  name: "tabs",
  data() {
    return {
      activeTab: "",
      path: null,
    };
  },
  computed: {
    ...mapState({
      currentTabs: (state) => state.tabs.currentTabs,
      cacheTabs: (state) => state.tabs.cacheTabs,
    }),
  },
  watch: {
    $route: {
      handler(neVal, oldVal) {
        this.activeTab = utils.getPath(neVal.name);
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    const currentPath = this.$route.path;
    if (currentPath == "/home") {
      this.refs && this.refs.tabs.style.zIndex == 1;
    } else {
      this.refs && this.refs.tabs.style.zIndex == 4;
    }
  },
  methods: {
    findPortalMenu(menuList, path, key) {
      for (let i = 0; i < menuList.length; i++) {
        if (path.replace("/", "") === menuList[i].webUri) {
          this.path = key;
        }
        if (menuList[i].children.length) {
          this.findPortalMenu(menuList[i].children, path, key);
        }
      }
    },
    tabClick(ins) {
      let leftMenu = JSON.parse(sessionStorage.authorityMenu);
      for (const key in leftMenu) {
        this.findPortalMenu(leftMenu[key], ins.name, key);
      }

      const portalMenu = utils.findPortalMenu(leftMenu, this.path);
      this.$store.dispatch("menu/CHANGE_MENU", portalMenu);

      // 切换tab栏，清空route.children中除第一项其他path，以解决切换tab后，点击返回调用$router.go(-1)  返回错误问题  start
      // const currentTabs = store.state.tabs.currentTabs;
      // let newCurrentTabs = []
      // for (let i = 0; i < currentTabs.length; i++) {
      //   let newChildren = currentTabs[i].children && currentTabs[i].children.length ? [currentTabs[i].children[0]] : []
      //   newCurrentTabs.push({
      //     ...currentTabs[i],
      //     children: newChildren
      //   })
      // }
      // this.$store.dispatch("tabs/SET_TABS", newCurrentTabs);
      // 切换tab栏，清空route.children中除第一项其他path，以解决切换tab后，点击返回调用$router.go(-1)  返回错误问题  end

      this.$router.push(ins.name);
    },
    tabRemove(name) {
      if (this.currentTabs.length === 1) {
        this.$message({
          showClose: true,
          message: "这已经是最后一个tab了哦",
          type: "warning",
        });
        return;
      }
      if (name == this.activeTab) {
        this.currentTabs.forEach((v, ind) => {
          if (v.path == name) {
            let nextTab = this.currentTabs[ind + 1] || this.currentTabs[ind - 1];
            this.$router.push(nextTab.path);
          }
        });
      }
      this.$store.dispatch("tabs/CLOSE_TAB", name);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="stylus">
.tabs
  background-color #ffffff;
  margin: -20px -20px 20px -20px;
  position: sticky;
  top: -20px;
  z-index: 3;
  width: calc(100vw - 224px);
  >>>.el-tabs__header
    margin 0
    .el-tabs__item
      color: #B2BCC8;
      &.is-active, &:hover
        color: #0f6eff
</style>
