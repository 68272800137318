var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu" }, [
    _c(
      "div",
      { staticClass: "scrollWrapper" },
      [
        ["/home", "/java_home"].includes(_vm.$route.path)
          ? _c(
              "div",
              {
                staticClass: "home_icon",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/portal")
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("./img/home.png"), alt: "" },
                }),
              ]
            )
          : _vm._e(),
        _c(
          "el-menu",
          {
            staticClass: "el-menu-vertical-demo",
            attrs: {
              "collapse-transition": false,
              "default-active": _vm.activeMenu,
              router: false,
              "text-color": "#4F5C70",
              collapse: _vm.isCollapse,
              "unique-opened": "",
              "active-text-color": "#0F6EFF",
            },
            on: { select: _vm.menuClick },
          },
          [
            _c("SubMenu", {
              attrs: {
                menuData: _vm.currentPortalMenu,
                isCollapse: _vm.isCollapse,
              },
              on: { changeState: _vm.collapseClick },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }