var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index height100" },
    [
      _c(
        "el-container",
        { staticClass: "height100" },
        [
          _c(
            "el-header",
            {
              class: { noHeight: _vm.noHeihgt },
              staticStyle: { height: "46px", padding: "0" },
            },
            [_c("Header")],
            1
          ),
          _c(
            "el-container",
            { staticClass: "bottomC" },
            [
              _c("div", { staticClass: "aside" }, [_c("SideBar")], 1),
              _c(
                "el-main",
                {
                  staticClass: "main-bgc",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("Tabs"),
                  _c(
                    "keep-alive",
                    {
                      attrs: { include: _vm.$store.getters["tabs/cacheTabs"] },
                    },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }